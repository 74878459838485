import React, { useContext, useState } from "react";
import { Link, navigate } from "gatsby";

import { CurrentUserContext } from "../providers/auth";

import Layout from "../components/layout";
import Seo from "../components/seo";
import SignInWithGoogle from "../components/sign-in-with-google";

const SignInPage = () => {
    return (
        <Layout>
            <Seo title="Sign In" />
            <div className="flex flex-col md:justify-center items-center p-4 w-full h-screen -mt-16 pt-16">
                <div className="absolute inset-0 mesh-bg -z-10" />
                {<SignInBox />}
            </div>
        </Layout>
    );
};

export default SignInPage;

// local components

const SignInBox = () => {
    const currentUser = useContext(CurrentUserContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const onSignIn = (email) => {
        setError(null);
        if (window.localStorage.getItem("firstSignUpEmail") === email) {
            window.localStorage.removeItem("firstSignUpEmail");
            navigate("/account");
            return;
        } else navigate("/");
    };

    return (
        <div className="overflow-auto flex flex-col rounded w-full md:w-1/2 lg:w-1/3 bg-lightgreen1 px-4 py-6">
            <h1 className="text-lg mb-2">Sign in</h1>
            <div className="flex flex-row justify-center">
                <SignInWithGoogle />
            </div>
            <div className="flex flex-row my-2">
                <hr className="flex-grow border-b border-grey-light" />
                <span className="mx-2 text-sm">or</span>
                <hr className="flex-grow border-b border-grey-light" />
            </div>
            <input
                className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <div className="text-left pl-2">
                <Link
                    to="/reset-password"
                    className="text-black font-bold text-xs underline"
                >
                    Forgot password?
                </Link>
            </div>
            <button
                className="primary btn my-4"
                onClick={() => {
                    if (!email || !password) {
                        setError({
                            message: "User email and password are required.",
                        });
                        return;
                    }
                    if (email.indexOf(" ") >= 0) {
                        setError({
                            message: "Email shall not contain space.",
                        });
                        return;
                    }
                    currentUser
                        .signIn(email, password)
                        .then(() => {
                            onSignIn(email);
                        })
                        .catch((err) => {
                            setError(err);
                        });
                }}
            >
                Sign in
            </button>
            {error ? (
                <div className="flex flex-row justify-center items-center px-2 pt-1 pb-4 text-red text-xs">
                    {error.message}
                </div>
            ) : null}
            <div className="flex flex-row items-center">
                <label className="flex-grow text-sm ml-2 leading-normal">
                    By clicking "Sign in" or "Sign in with Google", you agree to
                    our{" "}
                    <a
                        href="https://cpas.earth/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="italic text-blue underline"
                    >
                        Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                        href="https://cpas.earth/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="italic text-blue underline"
                    >
                        Privacy Policy
                    </a>
                    .
                </label>
            </div>
            <hr className="w-full border-b border-grey-light my-4" />
            <div className="flex flex-row justify-center items-center text-base">
                <span className="px-2">Don't have an account?</span>
                <Link to="/signup" className="text-black font-bold underline">
                    Sign up
                </Link>
            </div>
        </div>
    );
};
